import React, { forwardRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import AuthContext from '../../context/authContext';
import Icon from '../Icon';
import AuthButton from '../AuthButton';

import './styles.scss';

const ButtonDropdown = forwardRef(
  ({ className, children, PDFs, locked, ...other }) => {

    const authContext = useContext(AuthContext);
      const [isActive, setIsActive] = useState(false);


      const handleClick = event => {
        setIsActive(current => !current);
      };

      return (
        <>
        {locked && !authContext.loggedIn ? (
          <div>
          <div className={'dropdown-button'} onClick={handleClick} {...other}>
          {children}
          </div>
          <ul className={isActive ? 'button-dropdown--show' : 'button-dropdown'} aria-labelledby="dropdownMenuButton">
            {PDFs.map((pdf) => {
              return (
              <li>
                <AuthButton className="dropdown-item" type="traneLogin" ready={authContext.ready} buttonText={pdf.language}/>
              </li>
              )
              })}
          </ul>
        </div>
          ):(
            <div>
              <div className={'dropdown-button'} onClick={handleClick} {...other}>
              {children}
              </div>
              <ul className={isActive ? 'button-dropdown--show' : 'button-dropdown'} aria-labelledby="dropdownMenuButton">
                {PDFs.map((pdf) => {
                  return (
                  <li>
                    <a class="dropdown-item" href={pdf.file}  target="_blank">
                      {pdf.language}
                    </a>
                  </li>
                  )
                  })}
              </ul>
            </div>
          )
        }   
      </>
    );
//END OF RETURN


  }
);

ButtonDropdown.defaultProps = {
  className: null,
};

ButtonDropdown.propTypes = {
  /** A path to an internal page or a URL to an external page. */
  to: PropTypes.string,
  /** A custom class name */
  className: PropTypes.string,
  /** Inverts the buttons colors. */
  inverse: PropTypes.bool,
  /** Disables events and mutes the colors. */
  disabled: PropTypes.bool,
  /** Renders a button element that is styled like a link */
  link: PropTypes.bool,
};

export default ButtonDropdown;

import React, { useContext } from 'react';
import AuthButton from '../AuthButton';
import AuthContext from '../../context/authContext';

const LoginLogoutBlock = () => {
  const authContext = useContext(AuthContext);

  const renderBlock = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {authContext.loggedIn ? (
          <AuthButton type="traneLogout" ready={authContext.ready} />
        ) : (
          <AuthButton type="traneLogin" ready={authContext.ready} buttonText="Trane Technologies SSO Login"/>
        )}
      </div>
    );
  };
  return <>{authContext.ready && renderBlock()}</>;
};

export default LoginLogoutBlock;

import React, { useState } from 'react';
import axios from 'axios';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import { Form, TextInput, SubmitButton } from '../components/Form';
import Image from '../components/Image';
import Icon from '../components/Icon';
import OutboundLink from '../components/OutboundLink';
import Callout from '../components/Callout';
import Quote from '../components/Quote';
import Hero from '../components/Hero';
import Resource from '../components/Resource';
import LoginLogoutBlock from '../components/LoginLogoutBlock';
import ButtonDropdown from '../components/ButtonDropdown';
import volunteerGuide from '../pdfs/SustainableFutures-VolunteerGuide.pdf';
import vftGuide from '../pdfs/SustainableFutures-VFT-VolunteerGuide-v2.pdf';
import introLetter from '../pdfs/SustainableFutures-IntroLetter-v2.pdf';

function IndexPage() {
  const [zip, setZip] = useState('');
  const [schools, setSchools] = useState([]);
  const [error, setError] = useState(null);

  const handleZipChange = (field, value) => {
    setZip(value);
  };

  const handleFormSubmit = () => {
    const validated = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);

    if (!validated) {
      setError(`Please enter a valid zip code.`);
    } else {
      axios
        .get(
          `https://discoveryed.my.salesforce-sites.com/WebLead/services/apexrest/Account`,
          {
            params: {
              postalCode: zip,
            },
          }
        )
        .then((schools) => {
          if (schools.data.length === 0) {
            setError(`No schools were found in ${zip}.`);
          } else {
            setSchools(schools.data);
            setError(null);
          }
        })
        .catch((error) => {
          setError(
            `We encountered an issue searching for schools in ${zip}. Please try again.`
          );
        });
    }
  };

  const handleFormReset = () => {
    setZip('');
    setSchools([]);
    setError(null);
  };

  return (
    <Layout title="Volunteers Resources">
      <Hero
        mediaSource={'img-employeepg-hero-notext.jpg'}
        textOverlay={'Volunteer Resources'}
      />
      <Section className="employee-volunteers">
        <Container>
          <Row>
            <Column size={8}>
              <p>
                As part of a team of global climate innovators, Trane
                Technologies employees know what it means to incorporate
                sustainable solutions into daily life. Sustainable Futures
                volunteers bring experiential learning right into the classroom,
                connecting students to the diverse team at Trane Technologies
                and their broad range of talents. Students will learn to create
                sustainable goals as they harness essential STEM skills and
                investigate in-demand green career pathways.
              </p>
            </Column>
            <Column size={4}>
              <LoginLogoutBlock />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2 id="resources">Volunteer Resources</h2>
          <Row>
            <Column size={6} className="employee-volunteers__content">
              <Image filename="img-emplyeepg-volguide.jpg" />
              <h3 className="uppercase">Volunteer Guide</h3>
              <p>
                Sustainable Futures volunteers can make the most of their
                classroom visit with this resource guide. Find help for
                everything from setting up your session to engaging students
                with classroom challenges that address their interests and
                goals.
              </p>
              <p>
                <OutboundLink to={volunteerGuide} className="button">
                  <Icon name="download" marginRight />
                  Volunteer Guide
                </OutboundLink>
                <span className="ml-1">.PDF, 800KB</span>
              </p>
              <p>
                <OutboundLink to={vftGuide} className="button">
                  <Icon name="download" marginRight />
                  Virtual Field Trip Guide
                </OutboundLink>
                <span className="ml-1">.PDF, 996KB</span>
              </p>
            </Column>
            <Column size={6} className="employee-volunteers__content">
              <Image filename="img-employeepg-introltr.jpg" />
              <h3 className="uppercase">Introduction Letter</h3>
              <p>
                Reach out to your community and ignite interest in sustainable
                solution seeking with this Introduction Letter, which includes
                an overview of the <strong>Sustainable Futures</strong> program
                and mission, while outlining your role as an ambassador for
                everyday sustainability.
              </p>
              <p>
                <OutboundLink to={introLetter} className="button">
                  <Icon name="download" marginRight />
                  Introduction Letter
                </OutboundLink>
                <span className="ml-1">.PDF, 1.6MB</span>
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="volunteers__school-lookup mt-2">
        <Container>
          <Row>
            <Column size={8} offset={2}>
              <Row className="volunteers__school-lookup-border">
                <Column medium={8}>
                  <h2 className="uppercase">Find a School</h2>
                  {schools.length > 0 ? (
                    <button
                      className="button button--link mb-1"
                      onClick={handleFormReset}
                    >
                      &lsaquo; Search again
                    </button>
                  ) : (
                    <p>
                      Type in your zip code to find schools near you that may be
                      interested in having you volunteer in their classroom.
                    </p>
                  )}

                  {error && <p className="error-message">{error}</p>}

                  {schools.length > 0 && (
                    <div className="volunteers__school-lookup-results">
                      {schools.map((school, index) => (
                        <div
                          className="mb-2"
                          key={`${school.AccountName}-${index}`}
                        >
                          <h3 className="mb-0">{school.AccountName}</h3>
                          <div>{school.Type}</div>
                        </div>
                      ))}
                    </div>
                  )}
                  {schools.length === 0 && (
                    <Form onSubmit={handleFormSubmit}>
                      <TextInput
                        type="text"
                        label="Zip Code"
                        name="zip"
                        placeholder={'Enter your zip code'}
                        value={zip}
                        labelHidden
                        onChange={handleZipChange}
                      />
                      <SubmitButton>Search</SubmitButton>
                    </Form>
                  )}
                </Column>
                <Column
                  mediumOffset={1}
                  medium={3}
                  className="volunteers__school-lookup-img"
                >
                  <Image filename="icon-employeepg-findschool.png" />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className="dropdown-row">
            <h2 id="activities">Employee Volunteer Activities</h2>
            <ButtonDropdown
              PDFs={[
                {
                  language: 'Choosing a Healthy Environment',
                  file:
                    '/pdfs/SustainableFutures-EmployeeGuide-ChoosingHealthyEnvironment.pdf',
                },
                {
                  language: 'Food For Thought',
                  file:
                    '/pdfs/SustainableFutures-EmployeeGuide-FoodForThought.pdf',
                },
                {
                  language: 'Cold Chain Logistics',
                  file:
                    '/pdfs/SustainableFutures-EmployeeGuide-ColdChainLogistics.pdf',
                },
                {
                  language: 'Thermostats',
                  file:
                    '/pdfs/SustainableFutures-EmployeeGuide-Thermostats.pdf',
                },
                {
                  language: 'Carbon Footprints',
                  file:
                    '/pdfs/SustainableFutures-EmployeeGuide-CarbonFootprint-V1.0.pdf',
                },
                {
                  language: 'Green Building',
                  file:
                    '/pdfs/SustainableFutures-EmployeeGuide-GreenBuilding.pdf',
                },
                {
                  language: 'The Decarbonization Puzzle',
                  file:
                    '/pdfs/SustainableFutures-EmployeeGuide-DecarbPuzzle-V2.0.pdf',
                },
                {
                  language: 'Sustainability and Citizenship',
                  file:
                    '/pdfs/SustainableFutures-EmployeeGuide-SustCitizen-V2.0.pdf',
                },
              ]}
              locked
            >
              View all activities here
            </ButtonDropdown>
          </Row>
          <Row>
            <Column size={3}>
              <Resource
                img="employee-image-activity1-ChooseHealthy@2x.jpg"
                title="CHOOSING A HEALTHY ENVIRONMENT"
                description="Reimagine the daily routine through a sustainable lens as students consider how simple individual decisions can impact their environment."
                actions={[
                  {
                    label: 'Employee Guide',
                    download:
                      '/pdfs/SustainableFutures-EmployeeGuide-ChoosingHealthyEnvironment.pdf',
                  },
                ]}
                locked
              />
            </Column>
            <Column size={3}>
              <Resource
                img="employee-image-activity2-FoodThought@2x.jpg"
                title="FOOD FOR THOUGHT"
                description="Uncover the complex food systems that feed us with an activity in which students synthesize and communicate key findings."
                actions={[
                  {
                    label: 'Employee Guide',
                    download:
                      '/pdfs/SustainableFutures-EmployeeGuide-FoodForThought.pdf',
                  },
                ]}
                locked
              />
            </Column>
            <Column size={3}>
              <Resource
                img="employee-image-activity3-ColdChain@2x.jpg"
                title="COLD CHAIN LOGISTICS"
                description="Dive deeper into the meaning of sustainability with an activity spotlighting the importance of cold chain logistics in human health and well-being."
                actions={[
                  {
                    label: 'Employee Guide',
                    download:
                      '/pdfs/SustainableFutures-EmployeeGuide-ColdChainLogistics.pdf',
                  },
                ]}
                locked
              />
            </Column>
            <Column size={3}>
              <Resource
                img="employee-image-activity4-Thermostat@2x.jpg"
                title="CRITICAL THINKING: THERMOSTATS"
                description="Take students on a classroom gallery walk as they investigate the evolution of thermostats and consider sustainable innovations."
                actions={[
                  {
                    label: 'Employee Guide',
                    download:
                      '/pdfs/SustainableFutures-EmployeeGuide-Thermostats.pdf',
                  },
                ]}
                locked
              />
            </Column>
          </Row>
          <Row>
            <Column size={3}>
              <Resource
                img="employee-image-activity5-CarbonFootprint@2x.jpg"
                title="CARBON FOOTPRINTS"
                description="Show students how individual actions can add up to a real impact on the environment by determining their carbon footprint. After a short “What If” activity, students will imagine simple changes they could make to help reduce negative impacts on the environment. "
                actions={[
                  {
                    label: 'Employee Guide',
                    download:
                      '/pdfs/SustainableFutures-EmployeeGuide-CarbonFootprint-V1.0.pdf',
                  },
                ]}
                locked
              />
            </Column>
            <Column size={3}>
              <Resource
                img="employee-image-activity6-GreenBldg@2x.jpg"
                title="GREEN BUILDING"
                description="Take students inside the green building movement and explore innovative and eco-conscious new approaches to public energy, water, and waste. Students will brainstorm creative solutions for a green building design challenge, using the engineering design process."
                actions={[
                  {
                    label: 'Employee Guide',
                    download:
                      '/pdfs/SustainableFutures-EmployeeGuide-GreenBuilding.pdf',
                  },
                ]}
                locked
              />
            </Column>
            <Column size={3}>
              <Resource
                img="employee-image-activity7-Decarbonization@2x.jpg"
                title="THE DECARBONIZATION PUZZLE"
                description="Introduce students to the complexities of decarbonization by exploring how Trane Technologies and other businesses are tackling this larger-than-life challenge. Students will collaborate in a group-based jigsaw activity before considering what decarbonization means to individuals and families."
                actions={[
                  {
                    label: 'Employee Guide',
                    download:
                      '/pdfs/SustainableFutures-EmployeeGuide-DecarbPuzzle-V2.0.pdf',
                  },
                ]}
                locked
              />
            </Column>
            <Column size={3}>
              <Resource
                img="employee-image-activity8-Sustainability@2x.jpg"
                title="SUSTAINABILITY AND CITIZENSHIP"
                description="Break down the meaning of sustainability for students and explore the innovative methods being used to achieve it. After considering the connections between sustainability and citizenship, students will create an idea for a social media post that spotlights environmental sustainability."
                actions={[
                  {
                    label: 'Employee Guide',
                    download:
                      '/pdfs/SustainableFutures-EmployeeGuide-SustCitizen-V2.0.pdf',
                  },
                ]}
                locked
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Callout to="/virtual-field-trip">
        Watch the Virtual Field Trip Now
      </Callout>
      <Quote
        quote={
          'It’s hard to move mountains alone, but you can accomplish a lot when you get people moving toward the same goal together.'
        }
        img={'img-volunteers-quote.jpg'}
        name={'Dwayne Cowan'}
        title={'President'}
        company={'Thermo King, Europe, Middle East and Africa (EMEA)'}
        className={'quote--volunteers mt-2'}
      />
    </Layout>
  );
}

export default IndexPage;
